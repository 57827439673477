import { Injectable } from "@angular/core";
import { PaginationModel } from "../models/interfaces/pagination.model";
import { HttpHeaders, HttpParams } from "@angular/common/http";

export interface IRequestOptions {
  headers: HttpHeaders;
  observe?: 'body';
  params?: HttpParams;
  reportProgress?: boolean;
  responseType?: 'json' | any;
  withCredentials?: boolean;
  body?: any;
}

@Injectable({
  providedIn: 'root'
})

export class UtilsService {

  public generateSeed(): string {
    return (Math.random() + 1).toString(36).substring(7);
  }

  public getStandardPagination(page: number = 1, limit: number = 10): PaginationModel {
    return { page: page, take: limit, seed: this.generateSeed() }
  }

  public getHomePagination(): PaginationModel {
    return {page: 1, take: 999, seed: this.generateSeed() }
  }
}
