import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { CardItem } from '../models/interfaces/card-item.model';
import { IRequestOptions } from './utils.service';
import { FilterInputModel } from '../models/interfaces/filter-input.model';

@Injectable({
  providedIn: 'root',
})
export class FilterService extends BaseService {

  private filterSubject = new BehaviorSubject<string>('');
  private defaultFilter: string | null = 'dishes';

  constructor(private http: HttpClient) {
    super();
  }

  setFilter(filter: string) {
    this.filterSubject.next(filter);
  }

  getDefaultFilter() {
    return this.defaultFilter;
  }

  /* FILTER RESTAURANTS */
  filterRestaurants(filterInput: Partial<FilterInputModel>): Observable<CardItem[]> {
    const endpoint: string = this.getEndpoint(BaseService.ENDPOINT_NAME_FILTER_RESTAURANT);
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    let options: IRequestOptions = { headers: header, withCredentials:true } as IRequestOptions;
    return this.http.post<CardItem[]>(endpoint, filterInput, options);
  }

  /* FILTER DISHES */
  filterDishes(filterInput: FilterInputModel): Observable<CardItem[]> {
    const endpoint: string = this.getEndpoint(BaseService.ENDPOINT_NAME_FILTER_DISHES);
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    let options: IRequestOptions = { headers: header, withCredentials:true } as IRequestOptions;
    return this.http.post<CardItem[]>(endpoint, filterInput, options);
  }

  /* FILTER EXPERIENCES */
  filterExperiences(filterInput: FilterInputModel): Observable<CardItem[]> {
    const endpoint: string = this.getEndpoint(BaseService.ENDPOINT_NAME_FILTER_EXPERIENCES);
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    let options: IRequestOptions = { headers: header, withCredentials:true } as IRequestOptions;
    return this.http.post<CardItem[]>(endpoint, filterInput, options);
  }

  /* FILTER CHEFS */
  filterChefs(filterInput: FilterInputModel): Observable<CardItem[]> {
    const endpoint: string = this.getEndpoint(BaseService.ENDPOINT_NAME_FILTER_CHEFS);
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    let options: IRequestOptions = { headers: header, withCredentials:true } as IRequestOptions;
    return this.http.post<CardItem[]>(endpoint, filterInput, options);
  }



}
